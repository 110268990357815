import * as d3 from 'd3';

const mapContainer = document.getElementById('mapContainer');
const pinsData = JSON.parse(decodeURIComponent(mapContainer.dataset.pins));


const height = 550

// The svg
const svg = d3.select("#mapContainer")
    .append("svg")
    .attr("height", height)
    .attr("class", "w-full")

// Map and projection
const projection = d3.geoMercator()
    .center([0,0 ])                // GPS of location to zoom on
    .scale(200)
    .translate([500,340])


// This is like the zoom

// Create data for circle
// s:

const markers = [
];
pinsData.forEach(pin => {
    markers.push({
        lat: pin.lat,
        long: pin.lon,
        name: pin.name,
        city: pin.city,
    });
});

console.log(markers)


// Load external data and boot
d3.json("/world.geojson").then( function(data){

    // Filter data
    // data.features = data.features.filter( d => d.properties.name=="France")

    // Draw the map
    svg.append("g")
        .selectAll("path")
        .data(data.features)
        .join("path")
        .attr("fill", "#b8b8b8")
        .attr("d", d3.geoPath()
            .projection(projection)
        )
        .style("opacity", .4)

    // create a tooltip
    const Tooltip = d3.select("#mapContainer")
        .append("div")
        .attr("class", "tooltip absolute bg-white dark:bg-slate-500 dark:text-white border border-solid border-gray-500 rounded p-2")
        .style("opacity", 0);

    // Three function that change the tooltip when user hover / move / leave a cell
    const mouseover = function(event, d) {
        Tooltip.transition()
            .duration(200)
            .style("opacity", 1);
    }
    var mousemove = function(event, d) {
        const mapBounds = mapContainer.getBoundingClientRect();
        const circleBounds = this.getBoundingClientRect();
        // Calculate mouseX and mouseY relative to the SVG container
        const mouseX = event.x - mapBounds.left;
        const mouseY = event.y - mapBounds.top;

        // Update the position of the tooltip
        const tooltipOffsetX = 20;
        const tooltipOffsetY = -30;
        Tooltip.html('<b>' + d.name + '</b><br>' + d.city)

            .style("left", (mouseX + tooltipOffsetX) + "px")
            .style("top", (mouseY + tooltipOffsetY) + "px");

    }
    var mouseleave = function(event, d) {
        Tooltip.transition()
            .duration(500)
            .style("opacity", 0);
    }

    // Add circles:
    svg
        .selectAll("myCircles")
        .data(markers)
        .join("circle")
        .attr("cx", d => projection([d.long, d.lat])[0])
        .attr("cy", d => projection([d.long, d.lat])[1])
        .attr("r", 13)
        .attr("class", "circle")
        .style("fill", "#69b3a2")
        .attr("stroke", "#69b3a2")
        .attr("stroke-width", 3)
        .attr("fill-opacity", .4)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)

})